import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

// components
import AIAssistantComponent from '@pws-dev-portal/components/src/AIAssistant';

// services
import {
  postQuery,
  usePostFeedbacksMutation,
} from '../services/ai-assistant';

function AIAssistant() {
  const featureFlags = useFlags();

  // service
  const [postFeedbacks] = usePostFeedbacksMutation();

  return (
    <>
      {featureFlags?.aiPartnerAssistant && (
        <AIAssistantComponent
          postQuery={postQuery}
          postFeedbacks={postFeedbacks}
        />
      )}
    </>
  );
}

export default AIAssistant;
