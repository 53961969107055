import styled, { keyframes, css } from 'styled-components';
/* eslint-disable no-nested-ternary, operator-linebreak */

export const HeaderContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.75);
  padding: 15px;
  color: #fff;
  font-size: 20px;
  display: flex;
  flex-direction: row;
`;

export const TextAreaContainer = styled.div`
  width: 100%;

  // full view only
  padding: ${(props) => (props.isFullView && '0px 40px')};
`;

export const TextAreaWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: white;
`;

export const TextInputContaier = styled.textarea`
  width: 100%;
  padding: 8px 12px;
  resize: none;
  border: none;
  flex: 1;
  margin-top: 10px;

  border: none;
  overflow: auto;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  resize: none; /*remove the resize handle on the bottom right*/
`;

export const TiltIcon = styled.div`
  transform: rotate(-45deg);
  margin-bottom: 4px;
  margin-left: 4px;
`;

export const ChatMessage = styled.div`
  margin-top: 4px;
  display: flex;
  width: 100%;

  justify-content: ${(props) => (props.userType === 'user' ? 'flex-end' : 'flex-start')};

  .botMessage {
    margin-top: 4px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export const TextPopMessage = styled.span`
  color: ${(props) => (props.userType === 'user' ? 'white' : 'black')};
  background-color:  ${(props) => (props.userType === 'user' ? '#5f60ff' : '#fff')};
  border-radius: 12px 12px 0px 12px;
  padding: 8px 12px;
`;

export const SendIconContainer = styled.div`
  height: 100%;
  background: white;
  margin-right: 12px;
  display: flex;
  align-items: center;
`;

export const FooterContaier = styled.div`
  width: 100%;
  margin-top: 4px; 
  text-align: center;
`;

export const FooterText = styled.span`
  font-size: 10px;
  cursor: pointer;
`;

export const LoaderContainer = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: center;
`;

export const MsgArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const BotMessageContainer = styled.div`
  display: block;
  padding: 12px 12px 0px;
  background-color: white;
  border-radius: 12px 12px 12px 0px;
  margin-right: 24px;
  min-height: 24px;
  tr td,th {
    border: 1px solid rgba(0,0,0,0.2);
    padding: 5px;
  }
`;

const zoomInZoomOut = keyframes`
0% {
  transform: scale(1);
}
50% {
  transform: scale(0.9);
}
100% {
  transform: scale(1);
}
`;

// Use css helper to handle conditional animations
const animationStyles = css`
  ${zoomInZoomOut} 0.5s ease;
`;

export const AnimateIcons = styled.div`
  margin-left: 8px;
  margin-top: 8px;
  opacity: 1;

  // animate
  ${(props) => props.toAnimate && css`animation: ${animationStyles};`}
`;

// Define the keyframes for the bouncing animation
const bouncingLoader = keyframes`
  to {
    opacity: 0.25;
    transform: translateY(-8px);
  }
`;

// Create the styled wrapper
export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

// Create the styled bouncing divs
export const BouncingDiv = styled.div`
  width: 8px;
  height: 8px;
  margin: 2px 4px;
  border-radius: 50%;
  background-color: black;
  opacity: 1;
  animation: ${bouncingLoader} 0.6s infinite alternate;

  &:nth-child(2) {
    animation-delay: 0.2s;
  }

  &:nth-child(3) {
    animation-delay: 0.4s;
  }
`;

export const StyledIcon = styled.label`
  transition: 'background-color 0.3s ease';
  background-color: none;
  cursor: pointer;
  margin-right: 5px;
  padding: 2px 5px;

  &:hover, &:focus {
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.05);
    transform: scale(1.2);
  }
`;

export const MsgIconContainer = styled.div`
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background: #1d5aa6;
  align-content: center;
  text-align: center;
  cursor: ${(props) => (props.isEnable ? 'pointer' : 'default')};
  opacity: ${(props) => (props.isEnable ? '100%' : '50%')};
`;

export const MachineSelectorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  background: ${(props) => (!props.isFullView && 'gray')};
  margin: ${(props) => (props.isFullView && '16px 40px')};
  justify-content: ${(props) => (props.isFullView && 'center')};
`;

export const AnimateText = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  transition: opacity 1s ease-in-out;
  opacity: ${(props) => (props.fadeOut ? 0 : 1)};
`;

export const FeedbackListContainer = styled.div`
  padding: 12px;
  border: 1px;
  border-color: gray;
  border-style: solid;
  margin-top: 16px;
  border-radius: 4px;
`;

export const FeedbackListHeader = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FeedbackListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;
`;
