const constants = Object.freeze({
  /**
   * Feedback rating
   */
  FEEDBACK_RATINGS: {
    THUMBS_UP: 'thumbsUp',
    THUMBS_DOWN: 'thumbsDown',
  },
});

export default constants;
