import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from '@mui/material/Tooltip';

import CloseIcon from '@material-ui/icons/Close';
import RefreshIcon from '@material-ui/icons/Refresh';
import FullscreenIcon from '@material-ui/icons/Fullscreen';

import { HeaderContainer } from './styles';

const Header = ({
  loading,
  isFullView,
  onHandleRefesh,
  onHandleFullView,
  onHandleCloseChat,
}) => (
  <HeaderContainer>
    <span style={{ flex: 1 }}>Chat with Mercury</span>
    <Tooltip placement="bottom-start" title={'Clear Chat'}>
      <RefreshIcon
        data-testid="btn-refresh"
        onClick={onHandleRefesh}
        style={{
          color: loading ? 'gray' : 'white',
          fontSize: 24,
          cursor: loading ? 'default' : 'pointer',
          marginRight: 8,
        }}
        alt="refresh-icon"
      />
    </Tooltip>
    <Tooltip placement="bottom-start" title={isFullView ? 'Minimize' : 'Fullview'}>
      <FullscreenIcon
        data-testid="btn-fullview"
        onClick={onHandleFullView}
        style={{
          color: 'white',
          fontSize: 24,
          cursor: 'pointer',
          marginRight: 8,
        }}
        alt="fullview-icon"
      />
    </Tooltip>
    <Tooltip placement="bottom-start" title={'Close'}>
      <CloseIcon
        onClick={onHandleCloseChat}
        style={{ color: 'white', fontSize: 24, cursor: 'pointer' }}
        alt="close-icon"
        data-testid="btn-close"
      />
    </Tooltip>
  </HeaderContainer>
);

Header.propTypes = {
  loading: PropTypes.bool.isRequired,
  isFullView: PropTypes.bool.isRequired,
  onHandleRefesh: PropTypes.func.isRequired,
  onHandleFullView: PropTypes.func.isRequired,
  onHandleCloseChat: PropTypes.func.isRequired,
};

export default Header;
