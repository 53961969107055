import React from 'react';

import {
  FooterText,
  FooterContaier,
} from './styles';

const Footer = () => (
  <FooterContaier>
    {/** Add Disclaimer section */}
    <FooterText>
      <u>This chat may be recorded as described in our Privacy Policy.</u>
    </FooterText>
  </FooterContaier>
);

export default Footer;
