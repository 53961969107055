import React from 'react';
import PropTypes from 'prop-types';

import {
  BouncingDiv,
  LoaderWrapper,
  LoaderContainer,
} from './styles';

const BouncingLoader = ({ loading }) => (
  loading ? (
    <LoaderContainer>
      <LoaderWrapper>
        <BouncingDiv />
        <BouncingDiv />
        <BouncingDiv />
      </LoaderWrapper>
    </LoaderContainer>
  ) : null
);

BouncingLoader.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default BouncingLoader;
