import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { AnimateText } from './styles';

const AnimatedText = ({ text, duration = 5000, onHide }) => {
  const [visible, setVisible] = useState(true); // Controls if the text is displayed
  const [fadeOut, setFadeOut] = useState(false); // Controls the fade-out animation

  useEffect(() => {
    // Start fading out after `duration` milliseconds
    const timeout = setTimeout(() => {
      setFadeOut(true); // Start fade-out animation
      setTimeout(() => {
        setVisible(false);
        if (onHide) onHide(); // Notify parent when animation ends
      }, 100); // Remove after animation completes
    }, duration);

    return () => clearTimeout(timeout); // Cleanup on unmount
  }, [duration, onHide]);

  const handleClick = () => {
    setFadeOut(true); // Trigger fade-out animation
    setTimeout(() => {
      setVisible(false);
      if (onHide) onHide(); // Notify parent on manual hide
    }, 100); // Remove text after animation completes
  };

  return (
    visible && (
      <AnimateText onClick={handleClick} fadeOut={fadeOut}>
        <span style={{ textAlign: 'center' }}>
          <b>{text}</b>
        </span>
      </AnimateText>
    )
  );
};

AnimatedText.propTypes = {
  text: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired,
  onHide: PropTypes.bool.isRequired,
};

export default AnimatedText;
