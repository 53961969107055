import axios from 'axios';
import get from 'lodash/get';

import { retry, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { onHandleErrorResponse } from './error-handler-util';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_CMS_API_BASE_URL,
});

export const cmsApi = createApi({
  reducerPath: 'cmsApi',
  baseQuery: retry(async (args, api, extraOptions) => {
    const result = await baseQuery(args, api, extraOptions);
    if (result.error) {
      // handle rety request mechanism
      onHandleErrorResponse({ retry, result, dispatch: api.dispatch });
    }
    return result;
  }),
  endpoints: (builder) => ({
    // Get documents
    getDocuments: builder.query({
      query: () => '/v1/documents',
    }),
    // Get articles
    getArticles: builder.query({
      query: ({ status }) => `/v1/articles?filter[status]=${encodeURIComponent(status)}`,
    }),
    // Get the latest published articles. This will return either "blog"
    // or "business-program" type articles.
    // "count" is the number of articles to return
    getLatestArticles: builder.query({
      query: ({ count }) => `/v1/articles?filter[status]=published&limit=${count}`,
    }),
    // Get slug articles
    getSlugArticle: builder.query({
      query: ({ slug }) => `/v1/articles?filter[slug]=${encodeURIComponent(slug)}`,
      transformResponse: async (response) => {
        const url = get(response, 'results[0].content.data');
        if (url) {
          try {
            const results = await axios({
              method: 'GET',
              url,
              headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            });
            return results?.data ? {
              ...response?.results[0],
              content: results.data,
            } : null;
          } catch (err) {
            /* eslint-disable no-console */
            return null;
          }
        }
        return null;
      },
    }),
    // Get list of products
    getProducts: builder.query({
      query: () => '/v2/api-products',
    }),
    // Get announcements
    getAnnouncements: builder.query({
      query: () => '/v1/announcements',
    }),
    // Get api groups
    getApiGroups: builder.query({
      query: () => '/v1/api-groups',
    }),
    // Get api group by id
    getApiGroupById: builder.query({
      query: (id) => `/v1/api-groups/${id}`,
    }),
  }),
});

export const {
  useGetArticlesQuery,
  useGetProductsQuery,
  useGetDocumentsQuery,
  useGetSlugArticleQuery,
  useGetAnnouncementsQuery,
  useGetLatestArticlesQuery,
  // api groups query
  useGetApiGroupsQuery,
  useGetApiGroupByIdQuery,
} = cmsApi;
