import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { makeStyles } from '@material-ui/core/styles';
import CopyToClipboard from 'react-copy-to-clipboard';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import { oneDark as SyntaxStyle } from 'react-syntax-highlighter/dist/esm/styles/prism';

function SlideTransition(props) {
  return <Slide {...props} direction='up' />;
}

const useStyles = makeStyles({
  container: {
    position: 'relative',
  },
  button: {
    position: 'absolute',
    right: 0,
  },
  icon: {
    width: 20,
    height: 20,
    color: '#999',
  },
});

/**
 * This component is used internally by CodeViwer
 */
function CodeSyntax(props) {
  const classes = useStyles();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const {
    children, className, node, ...rest
  } = props;
  const match = /language-(\w+)/.exec(className || '');

  const handleClose = () => {
    setSnackbarOpen(false);
  };

  return match ? (
    <div className={classes.container}>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={snackbarOpen}
        onClose={handleClose}
        message='Copied to clipboard'
        autoHideDuration={2000}
        TransitionComponent={SlideTransition}
      />
      <CopyToClipboard text={String(children).replace(/\n$/, '')} onCopy={() => setSnackbarOpen(true)}>
        <IconButton className={classes.button}>
          <FileCopyIcon className={classes.icon} />
        </IconButton>
      </CopyToClipboard>
      <SyntaxHighlighter
        {...rest}
        language={match[1]}
        style={SyntaxStyle}
        showLineNumbers
      >
        {String(children).replace(/\n$/, '')}
      </SyntaxHighlighter>
    </div>
  ) : (
    <code {...rest} className={className}>
      {children}
    </code>
  );
}

CodeSyntax.propTypes = {
  children: PropTypes.any,
  className: PropTypes.any,
  node: PropTypes.any,
};

export default CodeSyntax;
