// MessageArea.js
import React from 'react';
import PropTypes from 'prop-types';
import remarkGfm from 'remark-gfm';
import ReactMarkdown from 'react-markdown';

import FeedbackSection from './FeedbackSection';
import BouncingLoader from './BouncingLoader';
import CodeSyntax from '../../CodeViewer/CodeSyntax';

import {
  MsgArea,
  ChatMessage,
  TextPopMessage,
  BotMessageContainer,
} from './styles';

const MessageArea = ({
  messages,
  loading,
  streamMessage,
  handleCopyClick,
  handleUpdateFeedback,
  tooltipText,
  updateFeedback,
  showFeedbackDialog,
  parentRef,
}) => {
  const shouldDisplayFeedbackIcons = (index) => {
    if (index === messages.length - 1) return !loading;
    return true;
  };

  return (
    <div>
      {messages.map((message, index) => (
        <MsgArea key={index}>
          <span style={{
            width: '100%',
            fontSize: 12,
            marginTop: 12,
            textAlign: `${message.sender === 'user' ? 'right' : 'left'}`,
          }}>
            {message.sender === 'user' ? 'You' : 'Mercury'}
          </span>
          {(streamMessage || message.text) && (
            <ChatMessage className='chat-message' userType={message.sender}>
              <div className='botMessage'>
                {message.type === 'text'
                  ? <TextPopMessage userType={message.sender}>{message.text}</TextPopMessage>
                  : (
                    <BotMessageContainer>
                      <ReactMarkdown
                        remarkPlugins={[remarkGfm]}
                        linkTarget="_blank"
                        components={{
                          code(props) {
                            return <CodeSyntax {...props} />;
                          },
                        }}>
                        {
                          (loading
                            && streamMessage
                            && messages.length - 1 === index)
                            ? streamMessage : message.text
                              .replace('api-reference-manals', 'api-reference-manuals')
                        }
                      </ReactMarkdown>
                    </BotMessageContainer>
                  )
                }
                {message.timeStamp && (
                  <span
                    style={{
                      padding: 2,
                      marginTop: 4,
                      background: 'none',
                      fontSize: 11,
                      color: 'gray',
                      textAlign: `${message.sender === 'user' ? 'right' : 'left'}`,
                    }}>
                    {message.timeStamp}
                  </span>
                )}
                {message.sender === 'bot'
                  && shouldDisplayFeedbackIcons(index)
                  && (
                    <FeedbackSection
                      message={message}
                      toAnimate={messages.length - 1 === index}
                      tooltipText={tooltipText}
                      handleCopyClick={handleCopyClick}
                      handleUpdateFeedback={handleUpdateFeedback}
                      updateFeedback={updateFeedback}
                      showFeedbackDialog={showFeedbackDialog}
                      parentRef={parentRef}
                    />
                  )
                }
              </div>
            </ChatMessage>
          )}
        </MsgArea>
      ))}
      <BouncingLoader loading={loading} />
    </div>
  );
};

MessageArea.propTypes = {
  messages: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  streamMessage: PropTypes.string.isRequired,
  handleCopyClick: PropTypes.func.isRequired,
  tooltipText: PropTypes.string.isRequired,
  handleUpdateFeedback: PropTypes.func.isRequired,
  updateFeedback: PropTypes.func.isRequired,
  showFeedbackDialog: PropTypes.func.isRequired,
  parentRef: PropTypes.any,
};

export default MessageArea;
