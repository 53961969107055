import React from 'react';
import PropTypes from 'prop-types';
import Button from '@digital-hig/button';

import { BtnSolid, BtnOutlined } from './styles';

const Buttons = (props) => {
  const {
    title,
    variant,
    analyticsloc,
    analyticsval,
  } = props;
  const renderButton = () => {
    switch (variant) {
      case 'contained':
        return <BtnSolid {...props}>{title}</BtnSolid>;
      case 'outlined':
        return <BtnOutlined {...props}>{title}</BtnOutlined>;
      default:
        return <Button {...props}>{title}</Button>;
    }
  };

  return (
    <div data-wat-loc={analyticsloc} data-wat-val={analyticsval}>
      {renderButton()}
    </div>
  );
};

Buttons.propTypes = {
  title: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  analyticsloc: PropTypes.string,
  analyticsval: PropTypes.string,
};

export default Buttons;
