import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useNavigate } from 'react-router-dom';

import isEmpty from 'lodash/isEmpty';

import find from 'lodash/find';

import Tab from '../Tab';

/**
 * Component to show horizontal navigation tabs
 */
const NavigationTabs = (props) => {
  const {
    env,
    tabs,
    location,
    loggedIn,
    selectedCompany,
    hasAcceptedTerms,
    onTabClick,
    onHandleMenuList,
  } = props;
  const [menuDict, setMenuDict] = useState({});
  const [mainNavSelected, setMainNavSelected] = useState('');
  const [subNavSelected, setSubNavSelected] = useState('');

  const navigate = useNavigate();

  // current path is taken from location
  // location object is only available in props if we use
  // withRouter()
  const currentPath = location.pathname;
  // Ensure navigation is seen if:
  //  - page is not dependent on deployment environment
  //  - page is only shown on development and staging deployments and not in production
  // @param {object} navObject
  const isNavObjectInCurrentEnv = (navObject) => ((Object.prototype.hasOwnProperty.call(navObject, 'showInEnv')
      && navObject.showInEnv.includes(env))
      && hasAcceptedTerms
      && selectedCompany)
      || !Object.prototype.hasOwnProperty.call(navObject, 'showInEnv');
  // Ensure navigation is seen if:
  //  - page does not require admin permissions
  //  - page requires admin permissions and user is logged in
  // @param {object} navObject
  const isNavObjectAdminAuthenticated = (navObject) => (
    navObject.needsLogin && navObject.needsLogin === loggedIn
  ) || !navObject.needsLogin;
  // filter out menu and sub menu fields based on deployment environment
  // and admin access eligibility of page
  useEffect(() => {
    const tempMenuObject = {};
    const tempMenuArray = [];
    tabs.forEach((navObject) => {
      if (
        navObject
        && isNavObjectInCurrentEnv(navObject)
        && isNavObjectAdminAuthenticated(navObject)
        && !navObject.disabled
      ) {
        const tempNavObject = { ...navObject };
        if (tempNavObject.subNav) {
          tempNavObject.subNav = tempNavObject.subNav.filter(
            (subNavObject) => subNavObject && isNavObjectInCurrentEnv(subNavObject),
          );
        }
        tempMenuObject[tempNavObject.mainNavCode] = tempNavObject;
        tempMenuArray.push(tempNavObject);
      }
      return false;
    });
    setMenuDict(tempMenuObject);
    onHandleMenuList(tempMenuObject);
  }, [tabs, env, loggedIn]);

  /**
   * Update navigations when path changes
   * (example: user action selected by menu / copied url / bookmark selected )
   */
  useEffect(() => {
    // TODO: clear selected if path is homepage
    if (currentPath) {
      const mainNav = currentPath.split('/');
      const mainPath = mainNav[1];
      const subPath = mainNav[2];

      // update main navigation with selected environment url
      if (mainPath === env) {
        const activeCompanySubUrl = mainNav[4];
        if (subPath === 'companies' && !isEmpty(activeCompanySubUrl)) {
          if (activeCompanySubUrl === 'test-api') {
            setMainNavSelected('my-apps');
            setSubNavSelected('test-api');
          } else if (activeCompanySubUrl === 'apps') {
            setMainNavSelected('my-apps');
            setSubNavSelected('my-apps');
          } else {
            setMainNavSelected(null);
            setSubNavSelected(null);
          }
        } else {
          setMainNavSelected(null);
          setSubNavSelected(null);
        }
      } else if (mainPath === 'blogs' && subPath === 'business-program') {
        setMainNavSelected(subPath);
      } else if (mainPath === 'api-products') {
        if (subPath === 'customer-success' || subPath === 'sales') {
          setMainNavSelected('sales');
        } else if (subPath === 'ordering' || subPath === 'quoting') {
          setMainNavSelected('ordering');
        }
      } else {
        setMainNavSelected(mainPath);
      }

      if (mainPath === 'sample-code') {
        setSubNavSelected(subPath.split('#')[0]);
      }
    }
  }, [env, currentPath]);

  const getClassName = (isMainNav, msgCode) => {
    let className = 'navbar-link';
    if (isMainNav) {
      className += ' navbar-mainNav-link';
      if (msgCode === mainNavSelected) {
        className += ' navbar-mainNav-selected';
      }
    } else {
      className += ' navbar-subNav-link';
      if (msgCode === subNavSelected) {
        className += ' navbar-subNav-selected';
      }
    }
    return className;
  };

  const getLink = (params) => {
    const {
      linkTo,
      isMainNav,
      elementLoc = '',
      subNavCode,
      displayText,
      mainNavCode,
      isExternalLink,
    } = params;
    const link = linkTo || '';
    const msgCode = isMainNav ? mainNavCode : subNavCode;
    const id = isMainNav ? `navbar-mainNav-${msgCode}` : `navbar-subNav-${msgCode}`;
    const className = getClassName(isMainNav, msgCode);

    // Callback when link is clicked
    const onLinkClick = () => {
      if (isMainNav) {
        setMainNavSelected(msgCode);
      } else {
        setSubNavSelected(msgCode);
      }
      onTabClick(params);
    };
    if (isExternalLink) {
      return (
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className={className}
          id={id}
          data-wat-val={displayText}
        >
          {displayText}
        </a>
      );
    }
    return (
      <Link
        to={link}
        className={className}
        id={id}
        {...(elementLoc !== '' ? { 'data-wat-loc': elementLoc } : {})}
        data-wat-val={displayText}
        onClick={onLinkClick}
      >
        {displayText}
      </Link>
    );
  };

  const getNavigationElements = (isMainNav) => {
    const mapArray = isMainNav ? tabs : menuDict[mainNavSelected].subNav;
    return mapArray.map((item) => {
      const key = isMainNav ? item.mainNavCode : `${mainNavSelected}_${item.subNavCode}`;
      const navCode = isMainNav ? item.mainNavCode : item.subNavCode;
      if (isNavObjectAdminAuthenticated(item)) {
        return (
          <li className={`nav-item nav-item-${navCode}`} key={key}>
            {getLink({
              ...item,
              isMainNav,
            })}
          </li>
        );
      }
      return null;
    });
  };

  const getMainNavigation = () => (
    <div className="main-navigation-container navigation-type-wrapper">
      <div className="container">
        <ul className="navbar-nav main-navigation-wrapper">{getNavigationElements(true)}</ul>
      </div>
    </div>
  );

  const getSubNavigation = () => {
    if (
      mainNavSelected
      && menuDict[mainNavSelected]
      && menuDict[mainNavSelected].subNav
      && menuDict[mainNavSelected].subNav.length > 0
    ) {
      const subNavMenus = menuDict[mainNavSelected].subNav;
      const tabOpts = subNavMenus?.map((menu) => menu.displayText);
      const tabSelected = find(subNavMenus, { subNavCode: subNavSelected })
        || tabOpts[0];
      return (
        <div className="container" style={{ marginTop: 8 }}>
          <Tab
            options={tabOpts}
            defaultValue={tabSelected?.displayText}
            onTabChange={(val) => {
              const selectedOption = find(subNavMenus, { displayText: val });
              setSubNavSelected(selectedOption.subNavCode);
              navigate(selectedOption.linkTo);
            }}
          />
        </div>
      );
    }
    return null;
  };

  return (
    <div className="navigation-container" data-wat-link-section="utilitynav">
      {getMainNavigation()}
      {getSubNavigation()}
    </div>
  );
};

NavigationTabs.propTypes = {
  /**
   * True if the user is logged in
   */
  loggedIn: PropTypes.bool.isRequired,
  /**
   * Callback when a tab is clicked
   */
  onTabClick: PropTypes.func.isRequired,
  /**
   * Environment selected by the user
   * e.g. "stg"
   */
  env: PropTypes.oneOf(['dev', 'stg', 'prd', 'int']),
  /**
   * The Tabs array
   */
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      /** Main navigation unique code */
      mainNavCode: PropTypes.string.isRequired,
      /** The link to go to when clicked */
      linkTo: PropTypes.string.isRequired,
      /** True if the navigation is only shown if the user is logged in */
      needsLogin: PropTypes.bool.isRequired,
      /** Text to display in the Tab */
      displayText: PropTypes.string.isRequired,
      /** Sub navigation */
      subNav: PropTypes.arrayOf(
        PropTypes.shape({
          /** Sub navigation unique id */
          subNavCode: PropTypes.string.isRequired,
          /** Link to go to when clicked */
          linkTo: PropTypes.string.isRequired,
          /** Text to display in the sub tab */
          displayText: PropTypes.string.isRequired,
          /** List of environment where to show this sub navigation */
          showInEnv: PropTypes.arrayOf(PropTypes.string),
        }),
      ),
    }),
  ).isRequired,
  /**
   * This is provided by react router via withRouter()
   */
  location: PropTypes.object.isRequired,

  onHandleMenuList: PropTypes.func.isRequired,

  /** The active selected company from user */
  selectedCompany: PropTypes.string,

  /**
   * True if the user already accepted tnc
   */
  hasAcceptedTerms: PropTypes.bool.isRequired,
};

export default NavigationTabs;
