import get from 'lodash/get';
import { retry, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { setSelectedCompany } from '../features/user/user-slice';

import { onHandleErrorResponse } from './error-handler-util';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_BASE_URL,
  // Add the forge access token in the Authorization header
  prepareHeaders: (headers, { getState, endpoint }) => {
    const {
      token: { accessToken },
    } = getState();

    // excludes settings for adding headers
    const excludedEndpoints = ['postContactUs'];

    if (accessToken && !excludedEndpoints.includes(endpoint)) {
      headers.set('authorization', `Bearer ${accessToken}`);
    }
    return headers;
  },
});

export const devPortalApi = createApi({
  reducerPath: 'devPortal',
  tagTypes: [
    'company',
    'profile',
    'companies',
    'companyApp',
    'developers',
    'companyApps',
  ],
  baseQuery: retry(async (args, api, extraOptions) => {
    const result = await baseQuery(args, api, extraOptions);
    if (result.error) {
      onHandleErrorResponse({ retry, result, dispatch: api.dispatch });
    }
    return result;
  }),

  endpoints: (builder) => ({
    // --- GET REQUEST --- //

    // Get user profile
    getProfile: builder.query({
      query: () => '/v2/users/@me',
      providesTags: ['profile'],
      transformResponse: (response) => {
        const profileImage = get(response, 'profileImages.sizeX120', '');
        return {
          ...response,
          profileImage,
          loginError: '',
        };
      },
    }),
    getIpAddress: builder.query({
      query: () => '/v2/ip-address',
    }),
    // Get available environments
    // This API is used to display the evironment selection in
    // the SelectEnvironment container
    getEnvironments: builder.query({
      query: () => '/v2/environments',
    }),
    // Get company list for the user given the environment
    // where env is enum: dev,stg,prd
    getCompanyList: builder.query({
      query: (env) => `/${env}/v2/companies`,
      providesTags: ['companies'],
    }),
    // Get the list of apps for the company
    getCompanyApps: builder.query({
      query: ({ env, name }) => `/${env}/v2/companies/${encodeURIComponent(name)}/apps`,
      providesTags: ['companyApps'],
    }),
    // Get company details given the environment and
    // company machine name
    getCompanyInfo: builder.query({
      query: ({ env, name }) => `/${env}/v2/companies/${encodeURIComponent(name)}`,
      providesTags: ['company'],
    }),
    // Get the list of developers for the company
    getCompanyDevelopers: builder.query({
      query: ({ env, name }) => `/${env}/v2/companies/${encodeURIComponent(name)}/developers`,
      providesTags: ['developers'],
    }),
    // Get the selected company app details
    getCompanySelectedApp: builder.query({
      query: ({ env, name, appName }) => `/${env}/v2/companies/${encodeURIComponent(name)}/apps/${encodeURIComponent(appName)}`,
      providesTags: ['companyApp'],
    }),

    // --- POST REQUEST --- //

    // Invite developer for the company
    postInviteDeveloper: builder.mutation({
      query: ({ env, name, payload }) => ({
        url: `/${env}/v2/companies/${encodeURIComponent(name)}/developers`,
        method: 'POST',
        body: payload,
      }),
      // patch invited developer to developers list
      async onQueryStarted({ env, name }, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        try {
          dispatch(
            devPortalApi.util.updateQueryData('getCompanyApps', { env, name }, (developers) => {
              developers.results.push(data);
            }),
          );
        } catch (err) {
          /* eslint-disable no-console */
          console.error(err);
        }
      },
    }),
    // Send feedback request
    postContactUs: builder.mutation({
      query: ({ accessToken, payload }) => ({
        url: '/v2/feedbacks',
        method: 'POST',
        body: payload,
        headers: {
          authorization: accessToken ? `Bearer ${accessToken}` : '',
        },
      }),
    }),
    // Send tsr request
    postTsrs: builder.mutation({
      query: ({ env, name, payload }) => ({
        url: `/${env}/v2/companies/${encodeURIComponent(name)}/tsrs`,
        method: 'POST',
        body: payload,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        try {
          dispatch(setSelectedCompany(data));
        } catch (err) {
          /* eslint-disable no-console */
          console.error(err);
        }
      },
      invalidatesTags: ['companies'],
    }),
    // Create app for company
    postCreateApp: builder.mutation({
      query: ({ env, name, payload }) => ({
        url: `/${env}/v2/companies/${encodeURIComponent(name)}/apps`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['companyApps'],
    }),
    // update app for company
    putUpdateApp: builder.mutation({
      query: ({
        env,
        name,
        appName,
        payload,
      }) => ({
        url: `/${env}/v2/companies/${encodeURIComponent(name)}/apps/${encodeURIComponent(appName)}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['companyApp', 'companyApps'],
    }),
    // Create app credential
    postCreateAppCredential: builder.mutation({
      query: ({
        env,
        name,
        appName,
        payload,
      }) => ({
        url: `/${env}/v2/companies/${encodeURIComponent(name)}/apps/${encodeURIComponent(
          appName,
        )}/credentials`,
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: ['companyApp', 'companyApps'],
    }),
    // update user profile
    putUpdateUserProfile: builder.mutation({
      query: ({
        payload,
      }) => ({
        url: '/v2/users/@me',
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: ['profile'],
    }),

    // --- DELETE REQUEST --- //
    // delete developer from the company
    deleteDeveloper: builder.mutation({
      query: ({ env, name, email }) => ({
        url: `/${env}/v2/companies/${encodeURIComponent(name)}/developers/${encodeURIComponent(
          email,
        )}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['developers'],
    }),
    // delete app
    deleteApp: builder.mutation({
      query: ({ env, name, appName }) => ({
        url: `/${env}/v2/companies/${encodeURIComponent(name)}/apps/${encodeURIComponent(appName)}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['company', 'companyApps'],
    }),
    // delete app credential
    deleteAppCredential: builder.mutation({
      query: ({
        env,
        name,
        appName,
        consumerKey,
      }) => ({
        url: `/${env}/v2/companies/${encodeURIComponent(name)}/apps/${encodeURIComponent(
          appName,
        )}/credentials/${consumerKey}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['companyApp', 'companyApps'],
    }),
  }),
});

export const {
  useGetProfileQuery,
  useGetIpAddressQuery,
  useGetCompanyAppsQuery,
  useGetCompanyInfoQuery,
  useGetCompanyListQuery,
  useGetEnvironmentsQuery,
  useGetCompanyDevelopersQuery,
  useGetCompanySelectedAppQuery,
  // mutation actions
  usePostTsrsMutation,
  useDeleteAppMutation,
  usePutUpdateAppMutation,
  usePostContactUsMutation,
  usePostCreateAppMutation,
  useDeleteDeveloperMutation,
  useDeleteAppCredentialMutation,
  usePostInviteDeveloperMutation,
  usePutUpdateUserProfileMutation,
  usePostCreateAppCredentialMutation,
} = devPortalApi;
