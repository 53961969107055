import styled from 'styled-components';

import Button from '@digital-hig/button';

export const BtnSolid = styled(Button)`
  background: black !important;
  color: white !important;
  padding: 6px 16px !important;
  border-radius: 4px !important;
  font-family: ArtifaktElement,sans-serif !important;
  text-transform: none !important;
  opacity: ${(props) => (props.disabled ? '0.4' : '1.0')};

  &:hover {
    outline: 1px solid #4d4ecf !important;
  }
  &:focus {
    outline: 2px solid #4d4ecf !important;
  }
  &:active {
    outline: 2px solid #2bc275 !important;
  }
`;

export const BtnOutlined = styled(Button)`
  border: 1px solid black !important;
  padding: 6px 16px !important;
  border-radius: 4px !important;
  color: black !important;
  font-family: ArtifaktElement,sans-serif !important;
  text-transform: none !important;

  &:hover {
    outline: 1px solid #4d4ecf !important;
  }
  &:focus {
    outline: 2px solid #4d4ecf !important;
  }
  &:active {
    outline: 2px solid #2bc275 !important;
  }
`;
