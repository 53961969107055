/**
 *
 * UserInputTypes: TextInput
 * This component is of input element with type="text".
 * It consists of 3 parts:
 * Label (optional)
 * Input
 * HelperHoverTips Message (optional)
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';
import { formattedMessage } from '../utils/formattedMessage';

import TextField from '../TextField';
import HoverHelpTips from '../HoverHelpTips';

/**
 * Explaining Props used
 * @param {string} inputName unique name to identify input in the form/component/page
 * @param {function} handleInputChange control input value usage in parent component
 *
 * OPTIONAL PROPS PASSING
 * @param {string} inputClassName additional classes to be added to <input> classNames
 * @param {regex} replaceCharWithEmptyRegex replace string matching regex to restrict user input
 * @param {number} minLength min length of characters needed
 * @param {number} maxLength max length of characters needed
 * @param {boolean} inputIsMust add asterisk for label and ensure value exists
 * @param {boolean} inputIsError identify style when input entered is incorrect
 * @param {object} labelMessageObject translation message object
 * @param {object} hoverTipsMessageObject translation message object and value
 * @param {string} value track the input from parent component
 */

const TextInput = (props) => {
  const {
    value,
    fullWidth,
    inputName,
    minLength,
    maxLength,
    placeholder,
    inputIsMust,
    inputIsError,
    inputClassName,
    handleInputChange,
    labelMessageObject,
    hoverTipsMessageObject,
    replaceCharWithEmptyRegex,
  } = props;

  const [minLengthRequired] = useState(minLength || 0);
  const [maxLengthRequired] = useState(maxLength || 50);

  const handleInputChangeValue = (e) => {
    const { value: val } = e.target;
    let inputElement = val.replace(/(\s\s+)/gm, ' ');
    if (replaceCharWithEmptyRegex) {
      inputElement = val.replace(replaceCharWithEmptyRegex, '');
    }
    handleInputChange(inputElement);
  };

  return (
    <div
      style={{ width: fullWidth && '100%' }}
      className={`userInputType-wrapper userInputType-text-input-wrapper input-${inputName}-wrapper`}
    >
      {labelMessageObject && (
        <label htmlFor={`input-${inputName}`}>
          {formattedMessage(labelMessageObject)}
          {inputIsMust && <span className="input-is-must-asterisk">*</span>}
        </label>
      )}

      <TextField
        style={{ width: fullWidth && '100%' }}
        id={`input-${inputName}`}
        name={inputName}
        type="text"
        value={value}
        margin="dense"
        variant="outlined"
        onChange={handleInputChangeValue}
        minLength={minLengthRequired}
        maxLength={maxLengthRequired}
        className={`input input-text-field input-width ${inputClassName || ''} ${
          inputIsError ? 'input-error' : ''
        }`}
        inputProps={{
          style: { fontSize: 14, width: fullWidth && '100%' },
          'data-testid': `input-${inputName}`,
        }}
        placeholder={placeholder}
      />

      {hoverTipsMessageObject && (
        <div className="input-hover-notify-wrapper">
          <HoverHelpTips>
            <div>
              <FormattedMessage
                id={hoverTipsMessageObject.message.id}
                defaultMessage={hoverTipsMessageObject.message.defaultMessage}
                description={hoverTipsMessageObject.message.description}
                values={hoverTipsMessageObject.value}
              />
            </div>
          </HoverHelpTips>
        </div>
      )}
    </div>
  );
};

TextInput.propTypes = {
  inputName: PropTypes.string.isRequired,
  inputClassName: PropTypes.string,
  handleInputChange: PropTypes.func.isRequired,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  inputIsMust: PropTypes.bool,
  inputIsError: PropTypes.bool,
  labelMessageObject: PropTypes.object,
  hoverTipsMessageObject: PropTypes.object,
  replaceCharWithEmptyRegex: PropTypes.objectOf(PropTypes.instanceOf(RegExp)),
  value: PropTypes.string,
  fullWidth: PropTypes.bool,
  placeholder: PropTypes.string,
};

export default TextInput;
