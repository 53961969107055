const storage = window.localStorage;

const SESSION_EXPIRY_KEY = 'session_expiry';

let sessionTimer = null;

/**
 * Save expiry time in session
 * @param {long} expiry
 */
export function setSessionExpiry({ expiry }) {
  storage.setItem(SESSION_EXPIRY_KEY, expiry);
}

/**
 * Checks if session is still valid based on saved exipry session comparing to current time
 */
export function isSessionValid() {
  const sessionExpiry = JSON.parse(storage.getItem(SESSION_EXPIRY_KEY));
  return sessionExpiry >= new Date().getTime();
}

/**
 * Remove expiry time in session
 */
export function removeSessionKeys() {
  storage.removeItem(SESSION_EXPIRY_KEY);
}

/**
 * Stop existing timer for removing and checking session
 */
export function stopSessionTimer() {
  if (sessionTimer) {
    clearTimeout(sessionTimer);
  }
}

/**
 * Start session timer and validates user session
 * @param {number} sessionExpiryTime - session expiry time
 * @param {long} expiryTimeInterval - session expiry timer interval
 * @param {method} logoutUser - action when user logouts
 */
export function startSessionTimer({ sessionExpiryTime, expiryTimeInterval, revokeUserSession }) {
  sessionTimer = setTimeout(() => {
    revokeUserSession();
    stopSessionTimer();

    const latestSessionExipryTime = JSON.parse(storage.getItem(SESSION_EXPIRY_KEY));
    // compare state from stored data
    if (sessionExpiryTime === latestSessionExipryTime) {
      removeSessionKeys();
    }
  }, expiryTimeInterval * 1000);
}

/**
 * Validates user session by checking expiry time and current time
 * @param {method} loginUser - action when user logins
 * @param {method} updateUserSession - action when user has invalid session
 */
export function validateUserSession({ loginUser, updateUserSession }) {
  const sessionExpiry = JSON.parse(storage.getItem(SESSION_EXPIRY_KEY));

  const { pathname } = window.location;

  if (sessionExpiry && pathname !== '/index.html' && pathname !== '/callback') {
    // checks if user session is still valid
    if (isSessionValid()) {
      // re-login user
      loginUser();
    } else {
      // update user session
      updateUserSession();
      // @TODO: we add refresh-token here later on
    }
  }
}

/**
 * Redirect user if there's a defined next url after a user logins in
 * @param {obj} history
 */
export function sessionRedirectUrl({ navigate }) {
  // default re-direction url when user logins in
  const nextUrl = '/select-companies';
  navigate(nextUrl, { replace: true });
}
