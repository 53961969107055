/**
 *
 * HoverHelpTips
 * Source from => https://tutorialzine.com/2014/07/css-inline-help-tips
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

const HoverHelpTips = (props) => (
  <div className="hover-helptips-wrapper">
    <div className="hover-popup-wrapper">{props.children}</div>
  </div>
);

HoverHelpTips.propTypes = {
  children: PropTypes.object.isRequired,
};

export default HoverHelpTips;
